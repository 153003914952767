import { ActionTree } from "vuex";
import { StateInterface } from "..";
import { ICancellationReason } from "@/interfaces/global/ICancellationReason";
import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";

const URL_CANCELLATION_REASON = "/api/settings/cancellation-reason";

const actions: ActionTree<ICancellationReason, StateInterface> = {
  // Obtencion de resultados de cancelacion
  async cancellationReasonListAction({ commit }, params): Promise<object> {
    const transformedParams = {
      reference_center_id: params.reference_center,
      care_center_id: params?.care_center,
      status: params?.status,
      search: params?.search,
    };
    try {
      const { data } = await executeApi().get(URL_CANCELLATION_REASON, {
        params: transformedParams,
      });
      const { success, message } = data;
      commit("setCancellationReasons", data);
      return { success, message, data: data.data };
    } catch (error) {
      return errorException(error);
    }
  },

  //Crear motivos de cancelacion
  async createReason({ commit }, bodyRequest) {
    commit("setReason");
    try {
      const { data } = await executeApi().post(
        URL_CANCELLATION_REASON,
        bodyRequest
      );
      const { success, message } = data;
      commit("setReason", { success, message });
    } catch (error) {
      return errorException(error);
    }
  },

  //Editar motivo cancelacion
  async editReason({ commit }, reasonId: number) {
    commit("setReason");
    try {
      const { data } = await executeApi().post(
        `${URL_CANCELLATION_REASON}/${reasonId}`
      );
      const { success, message } = data;
      commit("setReason", { success, message });
    } catch (error) {
      return errorException(error);
    }
  },

  //Eliminar motivo de cancelacion
  async deleteReason({ commit }, reasonId: number) {
    commit("setReason");
    try {
      const { data } = await executeApi().delete(
        `${URL_CANCELLATION_REASON}/${reasonId}`
      );
      const { success, message } = data;
      commit("setReason", { success, message });
    } catch (error) {
      return errorException(error);
    }
  },

  //Actualizar el estado del motivo de cancelacion
  async updateReasonStatus({ commit }, { reasonId, status }) {
    try {
      const { data } = await executeApi().post(
        `${URL_CANCELLATION_REASON}/${reasonId}/status`,
        { status }
      );
      const { success, message } = data;
      commit("setReason", { reasonId, status });
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
};

export default actions;
